.App {
  text-align: center;
  font-family: 'CabinetGrotesk', sans-serif;
}

.App-logo {
  height: 40vmin;
  /* pointer-events: none; */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* App.css */
@keyframes twinkling {
  0% { opacity: 0.8; }
  50% { opacity: 0.3; }
  100% { opacity: 0.8; }
}

.starry-background {
  background-color: black;
  background-image:
    radial-gradient(circle at 2% 2%, rgba(33, 4, 85, 0.8), transparent 10%),
    radial-gradient(circle at 70% 60%, rgba(33, 4, 85, 0.8), transparent 15%),
    radial-gradient(circle at 40% 80%, rgba(33, 4, 85, 0.8), transparent 20%),
    /* radial-gradient(circle at 90% 20%, rgba(33, 4, 85, 0.8), transparent 35%), */
    radial-gradient(circle at 10% 50%, rgba(33, 4, 85, 0.8), transparent 15%),
    /* Star Layer */
    radial-gradient(20px 20px at 10% 15%, white, transparent 0%),
    radial-gradient(4px 4px at 30% 40%, white, transparent 100%),
    radial-gradient(4px 4px at 25% 10%, white, transparent 100%),
    radial-gradient(4px 4px at 70% 30%, white, transparent 100%),
    radial-gradient(4px 4px at 15% 85%, white, transparent 100%),
    radial-gradient(4px 4px at 50% 20%, white, transparent 100%),
    radial-gradient(4px 4px at 80% 45%, white, transparent 100%),
    radial-gradient(4px 4px at 65% 70%, white, transparent 100%),
    radial-gradient(4px 4px at 55% 90%, white, transparent 100%),
    radial-gradient(4px 4px at 20% 60%, white, transparent 100%),
    radial-gradient(4px 4px at 75% 25%, white, transparent 100%),
    radial-gradient(4px 4px at 90% 40%, white, transparent 100%),
    radial-gradient(4px 4px at 35% 75%, white, transparent 100%),
    radial-gradient(4px 4px at 5% 10%, white, transparent 100%),
    radial-gradient(4px 4px at 12% 35%, white, transparent 100%),
    radial-gradient(4px 4px at 48% 88%, white, transparent 100%),
    radial-gradient(4px 4px at 77% 10%, white, transparent 100%),
    radial-gradient(4px 4px at 60% 45%, white, transparent 100%),
    radial-gradient(4px 4px at 88% 75%, white, transparent 100%),
    radial-gradient(4px 4px at 25% 5%, white, transparent 100%),
    radial-gradient(4px 4px at 90% 90%, white, transparent 100%),
    radial-gradient(4px 4px at 40% 30%, white, transparent 100%),
    radial-gradient(4px 4px at 85% 20%, white, transparent 100%),
    radial-gradient(4px 4px at 70% 60%, white, transparent 100%),
    radial-gradient(4px 4px at 55% 15%, white, transparent 100%),
    radial-gradient(4px 4px at 3% 12%, white, transparent 100%),
    radial-gradient(4px 4px at 13% 50%, white, transparent 100%),
    radial-gradient(4px 4px at 16% 80%, white, transparent 100%),
    radial-gradient(4px 4px at 22% 23%, white, transparent 100%),
    radial-gradient(4px 4px at 28% 78%, white, transparent 100%),
    radial-gradient(4px 4px at 31% 45%, white, transparent 100%),
    radial-gradient(4px 4px at 36% 65%, white, transparent 100%),
    radial-gradient(4px 4px at 38% 15%, white, transparent 100%),
    radial-gradient(4px 4px at 42% 30%, white, transparent 100%),
    radial-gradient(4px 4px at 43% 50%, white, transparent 100%),
    radial-gradient(4px 4px at 47% 20%, white, transparent 100%),
    radial-gradient(4px 4px at 49% 85%, white, transparent 100%),
    radial-gradient(4px 4px at 53% 65%, white, transparent 100%),
    radial-gradient(4px 4px at 54% 45%, white, transparent 100%),
    radial-gradient(4px 4px at 56% 85%, white, transparent 100%),
    radial-gradient(4px 4px at 58% 35%, white, transparent 100%),
    radial-gradient(4px 4px at 60% 50%, white, transparent 100%),
    radial-gradient(4px 4px at 62% 25%, white, transparent 100%),
    radial-gradient(4px 4px at 63% 90%, white, transparent 100%),
    radial-gradient(4px 4px at 66% 60%, white, transparent 100%),
    radial-gradient(4px 4px at 67% 10%, white, transparent 100%),
    radial-gradient(4px 4px at 68% 40%, white, transparent 100%),
    radial-gradient(4px 4px at 70% 90%, white, transparent 100%),
    radial-gradient(4px 4px at 73% 20%, white, transparent 100%),
    radial-gradient(4px 4px at 74% 70%, white, transparent 100%),
    radial-gradient(4px 4px at 76% 15%, white, transparent 100%),
    radial-gradient(4px 4px at 78% 35%, white, transparent 100%),
    radial-gradient(4px 4px at 80% 60%, white, transparent 100%),
    radial-gradient(4px 4px at 82% 50%, white, transparent 100%),
    radial-gradient(4px 4px at 84% 5%, white, transparent 100%),
    radial-gradient(4px 4px at 85% 75%, white, transparent 100%),
    radial-gradient(4px 4px at 87% 10%, white, transparent 100%),
    radial-gradient(4px 4px at 88% 45%, white, transparent 100%),
    radial-gradient(4px 4px at 89% 90%, white, transparent 100%),
    radial-gradient(4px 4px at 91% 15%, white, transparent 100%),
    radial-gradient(4px 4px at 92% 60%, white, transparent 100%),
    radial-gradient(4px 4px at 93% 35%, white, transparent 100%),
    radial-gradient(4px 4px at 94% 50%, white, transparent 100%),
    radial-gradient(4px 4px at 96% 20%, white, transparent 100%),
    radial-gradient(4px 4px at 98% 90%, white, transparent 100%),
    radial-gradient(4px 4px at 98% 90%, white, transparent 100%),
    radial-gradient(4px 4px at 4% 25%, white, transparent 100%),
radial-gradient(4px 4px at 7% 55%, white, transparent 100%),
radial-gradient(4px 4px at 9% 70%, white, transparent 100%),
radial-gradient(4px 4px at 11% 30%, white, transparent 100%),
radial-gradient(4px 4px at 14% 60%, white, transparent 100%),
radial-gradient(4px 4px at 18% 40%, white, transparent 100%),
radial-gradient(4px 4px at 19% 75%, white, transparent 100%),
radial-gradient(4px 4px at 21% 10%, white, transparent 100%),
radial-gradient(4px 4px at 24% 55%, white, transparent 100%),
radial-gradient(4px 4px at 26% 65%, white, transparent 100%),
radial-gradient(4px 4px at 29% 35%, white, transparent 100%),
radial-gradient(4px 4px at 32% 50%, white, transparent 100%),
radial-gradient(4px 4px at 34% 20%, white, transparent 100%),
radial-gradient(4px 4px at 37% 40%, white, transparent 100%),
radial-gradient(4px 4px at 39% 60%, white, transparent 100%),
radial-gradient(4px 4px at 41% 10%, white, transparent 100%),
radial-gradient(4px 4px at 44% 70%, white, transparent 100%),
radial-gradient(4px 4px at 46% 30%, white, transparent 100%),
radial-gradient(4px 4px at 51% 25%, white, transparent 100%),
radial-gradient(4px 4px at 52% 55%, white, transparent 100%),
radial-gradient(4px 4px at 57% 15%, white, transparent 100%),
radial-gradient(4px 4px at 61% 80%, white, transparent 100%),
radial-gradient(4px 4px at 64% 50%, white, transparent 100%),
radial-gradient(4px 4px at 69% 35%, white, transparent 100%),
radial-gradient(4px 4px at 71% 55%, white, transparent 100%),
radial-gradient(4px 4px at 72% 45%, white, transparent 100%),
radial-gradient(4px 4px at 79% 25%, white, transparent 100%),
radial-gradient(4px 4px at 81% 15%, white, transparent 100%),
radial-gradient(4px 4px at 83% 65%, white, transparent 100%),
radial-gradient(4px 4px at 86% 30%, white, transparent 100%),
radial-gradient(4px 4px at 89% 20%, white, transparent 100%),
radial-gradient(4px 4px at 95% 40%, white, transparent 100%),
radial-gradient(4px 4px at 97% 60%, white, transparent 100%),
radial-gradient(4px 4px at 100% 50%, white, transparent 100%),
radial-gradient(4px 4px at 2% 70%, white, transparent 100%),
radial-gradient(4px 4px at 6% 5%, white, transparent 100%),
radial-gradient(4px 4px at 8% 90%, white, transparent 100%),
radial-gradient(4px 4px at 10% 80%, white, transparent 100%),
radial-gradient(4px 4px at 17% 25%, white, transparent 100%),
radial-gradient(4px 4px at 23% 45%, white, transparent 100%),
radial-gradient(4px 4px at 27% 55%, white, transparent 100%),
radial-gradient(4px 4px at 33% 75%, white, transparent 100%),
radial-gradient(4px 4px at 40% 50%, white, transparent 100%),
radial-gradient(4px 4px at 43% 35%, white, transparent 100%),
radial-gradient(4px 4px at 45% 65%, white, transparent 100%),
radial-gradient(4px 4px at 49% 40%, white, transparent 100%),
radial-gradient(4px 4px at 52% 70%, white, transparent 100%),
radial-gradient(4px 4px at 59% 25%, white, transparent 100%),
radial-gradient(4px 4px at 65% 45%, white, transparent 100%),
radial-gradient(4px 4px at 71% 65%, white, transparent 100%),
radial-gradient(4px 4px at 75% 55%, white, transparent 100%),
radial-gradient(4px 4px at 79% 45%, white, transparent 100%),
radial-gradient(4px 4px at 83% 35%, white, transparent 100%),
radial-gradient(4px 4px at 87% 25%, white, transparent 100%),
radial-gradient(4px 4px at 92% 35%, white, transparent 100%),
radial-gradient(4px 4px at 96% 55%, white, transparent 100%),
radial-gradient(4px 4px at 99% 65%, white, transparent 100%),
radial-gradient(4px 4px at 100% 30%, white, transparent 100%),
radial-gradient(4px 4px at 99% 80%, white, transparent 100%);
    
    
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}


/* @keyframes twinkling {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
} */
 
/* Particle Effect */
.particle {
  position: absolute;
  background-color: white; /* Change color as needed */
  border-radius: 50%;
  pointer-events: none;
  animation: particle-move 1.5s ease-out forwards, fade-out 1.5s ease-out forwards;
  opacity: 0.8; /* Slight initial opacity for subtlety */
  filter: blur(10px); /* Adds blur effect */
  z-index: 9999; /* Ensures particles are above all other content */

}

@keyframes particle-move {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(150px, 150px); /* Increased movement for larger particles */
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
